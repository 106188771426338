import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import "./categories.scss"

// Components
import Layout from "components/Layout"
import SEO from "components/SEO"
import PostLink from "components/PostLink"

// Utilities
import kebabCase from "lodash/kebabCase"
import capitalize from "lodash/capitalize"

const Categories = ({ pageContext, data }) => {
  const { category } = pageContext
  const { edges } = data.allMarkdownRemark
  const totalCount = edges.length
  const pathname = `/blog/category/${kebabCase(category)}`

  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout pathname="blog">
      <SEO title={`${capitalize(category)}`} pathname={pathname} />
      <div className="categories container">
        <div className="page-header">
          <div class="category">{capitalize(category)}</div>
          <div className="posts-count">{`${totalCount} posts.`}</div>
        </div>
        <div className="posts">
          {Posts}
        </div>
        <Link to="/blog/category">All categories</Link>
      </div>
    </Layout>
  )
}

Categories.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Categories

export const pageQuery = graphql`
  query($category: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            author
            title
            excerpt
            categories
          }
          fields {
            slug
          }
          html
        }
      }
    }
  }
`